// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-float infinite 3s ease-in-out;
//   }
// }

// .App-header {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
// }

// .App-link {
//   color: rgb(112, 76, 182);
// }

// @keyframes App-logo-float {
//   0% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(10px);
//   }
//   100% {
//     transform: translateY(0px);
//   }
// }
@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Bilo';
  src: url('../public/Bilo-Regular.woff2') format('woff2'),
      url('../public/Bilo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



body {
  margin: 0;
  font-family: "Bilo", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
}
@primary-color: #214F50;